import React from "react";
import './Background.css'
export default function Background() {
    return(
        <div className="backgroundPrimary" style ={backgroundStyle}></div>
    );
}


const backgroundStyle = {
    background: "#00a8a8",
    color: "#00a8a8"
  };