import Background from "./components/Background"
import HeaderBar from './components/header-bar/headerBar';
import './App.css'
function App() {
  return (
    <div className="App">
      <header className="App-header">
      <HeaderBar/>
      <Background/>  
      </header>
    </div>
  );
}

export default App;
 