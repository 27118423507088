import React from "react";
import './headerBar.css'
export default function HeaderBar() {
    return(
        <div className="headerBarPrimary" style ={backgroundStyle}>
            <h1 className="pomodoroDescription">The Pomodoro Technique is a time management method developed by Francesco Cirillo in the 
            <br /> late 1980s.
            </h1>
        </div>
        
    );
}


const backgroundStyle = {
    background: "#464646",
    color: "white",
  
  };